import React from 'react';
import { graphql } from 'gatsby';
import { Layout } from '../components/layout';
import { MarkdownBlock } from '../components/markdown-block';

export default ({ data }) => (
    <Layout title={data.markdownRemark.frontmatter.title}>
        <MarkdownBlock htmlAst={data.markdownRemark.htmlAst} />
    </Layout>
);

export const query = graphql`
    query PageQuery($slug: String!) {
        markdownRemark(fields: { slug: { eq: $slug } }) {
            htmlAst
            frontmatter {
                title
            }
        }
    }
`;
